*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.hero{
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/foosball.gif");
    background-repeat: no-repeat;
    background-size: 100%;
}
.get-started-button{
    margin: 0;
    position: absolute;
    top: 50%;
}

nav{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav .logo{
    width: 200px;
}

.myhead{
    font-size: 80px;
    color: #fff;
    font-weight: 300;
}

nav ul li{
    list-style: none;
    display: inline-block;
    margin-left: 40px;
}

nav ul li a{
    text-decoration: none;
    color: #fff;
    font-size: 17px;
}

.content{
    text-align: center;
}
.content h1{
    font-size: 160px;
    color: #fff;
    font-weight: 600;
}

.content h1:hover{
    -webkit-text-stroke: 2px #fff;
    color: transparent;
    transition: 2s;
}

.content a{
    text-decoration: none;
    display: inline-block;
    color: #fff;
    font-size: 24px;
    border: 2px solid #fff;
    padding: 14px 70px;
    border-radius: 50px;
    margin-top: 20px;
}

.back-video{
    position: absolute;
    right: 0;
    
    bottom: 0;
    margin-top: 100px;
    z-index: -1;

}

@media (min-aspect-ratio: 16/9){
    .back-video{
        width: 100%;
        height: auto;
    }
}

@media (max-aspect-ratio: 16/9){
    .back-video{
        width: auto;
        height: 100%;
    }
}