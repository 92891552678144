.card-container {
    align-items: center;
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(51,51,51,1) 45%, rgba(100,107,109,1) 48%, rgba(152,158,159,1) 51%);
    width: 60vw;
    min-width: 200px;
    max-width: 500px;
    height: 10em;
    margin: auto;
    border-radius: 25px 25px 25px 25px;
    margin-top: 5%;
    
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: auto auto;
    padding: 10px;
    max-height: 10em;
  }

.grid-item {
    font-size: 10px;
    text-align: center;
    max-height: 7.5em;
    white-space: nowrap;
    
  }

.team-name {
  font-size: 13px;
  text-align: center;
  max-height: 7.5em;
  white-space: nowrap;
}

.black-team-name {
  font-size: 13px;
  text-align: center;
  max-height: 7.5em;
  white-space: nowrap;
  color: red;
}

.goals-card-title{
  font-size: 12px;
  text-align: center;
  max-height: 7.5em;
  white-space: nowrap;
  color: rgb(165, 161, 161);
}

  #score {
    position: relative;
    margin: 0px;
    padding: 0;
    border: 0;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    display: flex;
    flex-wrap: wrap;
    /* for horizontal aligning of child divs */
    justify-content: center;
    /* for vertical aligning */
    align-items: flex-start;
    height: 7.5em;
}
.score-number {
    font-size: 2.5em;
    font-weight: 500;
    padding-right: 10px;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.nav-icon{
    padding-top: 5px;
    width: 45px;
    margin: auto
 }

 .red-goal-replay-button{
  border-radius: 50%;
  height: 25px;
  width: 25px;
  background-color: rgb(244, 236, 236);
  color: black;
  text-align: center;
  margin: 5px;
  
  border-width: 1px;
  border-style: solid;
  display: table-cell;
  vertical-align: middle;
  margin: 5px;
}

.goal-replay-button{
  border-radius: 50%;
  height: 25px;
  width: 25px;
  background-color: rgb(244, 236, 236);
  color: black;
  text-align: center;
  margin: 5px;
  border-width: 1px;
  border-style: solid;
  display: table-cell;
  vertical-align: middle;
  margin: 5px;
}