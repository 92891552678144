.games{
    margin-top: 0px;
    overflow: auto;
    height: 75vh;
    padding-bottom: 200px;
  }

.content-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: minmax(100px, auto);
}

.score-and-video {
  grid-column: 1;
  grid-row: 1 / 5;
  align-items: center;
}

.logo {
  background-image: url("../assets/aws_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
  opacity: 0.3;
}

.games-and-logo{
  grid-column: 2;
  grid-row: 1 / 5;

}