#score-background{
    border-radius: 25px 25px 0px 0px; 
    /*background-image: url("https://crossroadssportscary.org/wp-content/uploads/2021/01/green-grass-field-pattern-background-soccer-football-sports_64749-2365.jpg"); */
    text-align: center;
    font-size: 1.5em;
    color: w;
    width: 100%;
}

#score {
    position: relative;
    height: 10vh;
    width: 100%;
    margin: 0px;
    padding: 0;
    border: 0;
    font-family: Arial, Helvetica, sans-serif;
    color: white;

    display: flex;
    flex-wrap: wrap;
    /* for horizontal aligning of child divs */
    justify-content: center;
    /* for vertical aligning */
    align-items: center;
}
.score-number-main {
    font-size: 3.0em;
    font-weight: 500;
    padding-right: 10px;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.scoreboard-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
    grid-template-rows: auto auto;
    padding: 10px;
    max-height: 30vh;
    width: 100%;
  }
.scoreboard-item {
    font-size: 10px;
    text-align: center;
    max-height: 7.5em;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
}
#score-name{
    font-size: 15px;
    font-weight: 500;
    color: white;
}
#score-name-bottom{
    border-bottom: 4px solid;
    font-size: 15px;
    color: white;
}
#team-red-score-name{
    font-size: 15px;
    font-weight: 500;
    color: white;
}
#team-red-score-name-bottom{
    border-bottom: 4px solid red;
    font-size: 15px;
    color: white;
}

#score-goal-replays{
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    justify-items: center;
}

#team-red-score-goal-replays{
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    justify-items: center;
}

.goal-replay-button{
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background-color: rgb(244, 236, 236);
    color: black;
    margin: 5px;
    border-width: 1px;
    border-style: solid;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.black-goal-replay-button{
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background-color: rgb(244, 236, 236);
    color: black;
    margin: 5px;
    border-color: blue;
    border-width: 1px;
    border-style: solid;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}